import TheGlobalNavbar from '@/components/TheGlobalNavbar';
import renderWidget from '@/renderWidget';

/**
 * Only use this entrypoint if you need to render the Navbar as a widget on a non-React page.
 *
 * If the entire page is React, the `renderPage()` function takes care of rendering the Navbar as long as
 * the template is extending _base_react.html.
 */
renderWidget(
  <TheGlobalNavbar navbarConfigs={global.navbar_configs} />,
  'global-navbar',
);
